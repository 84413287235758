exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-posts-strapi-blog-article-slug-tsx": () => import("./../../../src/pages/blog/posts/{strapiBlogArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-posts-strapi-blog-article-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-blockchain-tsx": () => import("./../../../src/pages/test-blockchain.tsx" /* webpackChunkName: "component---src-pages-test-blockchain-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */),
  "component---src-pages-works-strapi-project-slug-tsx": () => import("./../../../src/pages/works/{strapiProject.slug}.tsx" /* webpackChunkName: "component---src-pages-works-strapi-project-slug-tsx" */)
}

